import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspace } from "ee/actions/workspaceActions";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { EntityExplorerWrapper } from "pages/Editor/Explorer/Common/EntityExplorerWrapper";
import Files from "pages/Editor/Explorer/Files";
import {
  ActionEntityContextMenuItemsEnum,
  FilesContextProvider,
} from "pages/Editor/Explorer/Files/FilesContextProvider";
import { getCurrentWorkflow } from "ee/selectors/workflowSelectors";
import { hasCreateWorkflowActionsPermission } from "ee/utils/permissionHelpers";
import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";

function WorkflowEntityExplorer({ isActive }: { isActive: boolean }) {
  const dispatch = useDispatch();

  const explorerRef = useRef<HTMLDivElement | null>(null);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  useEffect(() => {
    dispatch(fetchWorkspace(currentWorkspaceId));
  }, [currentWorkspaceId]);

  const workflow = useSelector(getCurrentWorkflow);

  const canCreateActions = hasCreateWorkflowActionsPermission(
    workflow?.userPermissions || [],
  );

  return (
    <EntityExplorerWrapper explorerRef={explorerRef} isActive={isActive}>
      <FilesContextProvider
        canCreateActions={canCreateActions}
        editorId={workflow?.id || ""}
        menuItems={[
          ActionEntityContextMenuItemsEnum.RENAME,
          ActionEntityContextMenuItemsEnum.DELETE,
        ]}
        parentEntityId={workflow?.id || ""}
        parentEntityType={ActionParentEntityType.WORKFLOW}
      >
        <Files />
      </FilesContextProvider>
    </EntityExplorerWrapper>
  );
}

WorkflowEntityExplorer.displayName = "WorkflowEntityExplorer";

export default WorkflowEntityExplorer;
