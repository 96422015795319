import React from "react";
import EditorSettingsPaneContainer from "pages/Editor/commons/EditorSettingsPaneContainer";
import SettingsPane from "./SettingsPane";
import { Divider, Flex } from "@appsmith/ads";

const PackageSettings = () => {
  return (
    <Flex>
      <EditorSettingsPaneContainer title="Package Settings">
        <SettingsPane />
      </EditorSettingsPaneContainer>
      <Divider orientation="vertical" />
    </Flex>
  );
};

export default PackageSettings;
