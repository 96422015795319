import { EditorState } from "ee/entities/IDE/constants";
import type { IDESidebarButton } from "IDE";

export const BottomButtons: IDESidebarButton[] = [
  {
    state: EditorState.SETTINGS,
    icon: "settings-2-line",
    title: "Settings",
    urlSuffix: "settings",
  },
];
