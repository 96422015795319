import React from "react";
import { Callout } from "@appsmith/ads";

import { createMessage, PRIVATE_ENTITY_DEPRECATE } from "ee/constants/messages";
// Note: Uncomment link when feature is released and docs are up.
// const PACKAGE_GUIDE_URL = "https://docs.appsmith.com/packages/overview";

function PrivateEntityDeprecationCallout() {
  return (
    <Callout
      isClosable
      kind="info"
      // links={[
      //   {
      //     children: "Learn more",
      //     to: PACKAGE_GUIDE_URL,
      //     target: "_blank",
      //   },
      // ]}
    >
      {createMessage(PRIVATE_ENTITY_DEPRECATE)}
    </Callout>
  );
}

export default PrivateEntityDeprecationCallout;
