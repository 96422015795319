export * from "ce/pages/Editor/IDE/MainPane/useRoutes";
import { default as useCE_Routes } from "ce/pages/Editor/IDE/MainPane/useRoutes";
import type { RouteReturnType } from "ce/pages/Editor/IDE/MainPane/useRoutes";
import {
  MODULE_INSTANCE_ID_PATH,
  WORKFLOW_GENERAL_SETTINGS_PATH,
  WORKFLOW_TRIGGER_SETTINGS_PATH,
  ADD_PATH,
} from "ee/constants/routes/appRoutes";
import { useSelector } from "react-redux";
import { getShowQueryModule } from "ee/selectors/moduleFeatureSelectors";
import ModuleEditor from "../../ModuleEditor";
import {
  MODULE_EDITOR_PATH,
  PACKAGE_EDITOR_PATH,
  PACKAGE_LIBRARIES_EDITOR_PATH,
} from "ee/constants/routes/packageRoutes";
import { getShowWorkflowFeature } from "ee/selectors/workflowSelectors";
import WorkflowQueryEditor from "../../WorkflowEditor/WorkflowQueryEditor";
import {
  SAAS_EDITOR_API_ID_PATH,
  WORKFLOWS_JS_COLLECTION_EDITOR_PATH,
  WORKFLOW_API_EDITOR_PATH,
  WORKFLOW_EDITOR_URL,
  WORKFLOW_QUERY_EDITOR_PATH,
} from "ee/constants/routes/workflowRoutes";
import WorkflowApiEditor from "../../WorkflowEditor/WorkflowApiEditor";
import TriggerWorkflowSettings from "../../WorkflowEditor/WorkflowSettingsPane/WorkflowSettings/TriggerWorkflowSettings";
import GeneralWorkflowSettings from "../../WorkflowEditor/WorkflowSettingsPane/WorkflowSettings/GeneralWorkflowSettings";
import WidgetsEditor from "pages/Editor/WidgetsEditor";
import WorkflowJsEditor from "../../WorkflowEditor/WorkflowJSEditor";

function useRoutes(path: string) {
  const ceRoutes = useCE_Routes(path);
  const showQueryModule = useSelector(getShowQueryModule);
  const showWorkflows = useSelector(getShowWorkflowFeature);
  let moduleRoutes: RouteReturnType[] = [];
  let workflowRoutes: RouteReturnType[] = [];

  if (showQueryModule) {
    moduleRoutes = [
      // Module instance is on App IDE, needs canvas to be shown
      {
        key: "ModuleInstance",
        component: WidgetsEditor,
        exact: true,
        path: [
          `${path}${MODULE_INSTANCE_ID_PATH}`,
          `${path}${MODULE_INSTANCE_ID_PATH}${ADD_PATH}`,
        ],
      },
      // On PackageIDE these routes show the Module Editor screen
      {
        key: "ModuleEditor",
        component: ModuleEditor,
        path: `${MODULE_EDITOR_PATH}`,
      },
      {
        key: "ModuleEditorJSLibs",
        exact: true,
        component: undefined,
        path: `${PACKAGE_EDITOR_PATH}${PACKAGE_LIBRARIES_EDITOR_PATH}`,
      },
    ];
  }

  if (showWorkflows) {
    workflowRoutes = [
      {
        key: "WorkflowQueryEditor",
        component: WorkflowQueryEditor,
        path: `${WORKFLOW_EDITOR_URL}${WORKFLOW_QUERY_EDITOR_PATH}`,
        exact: true,
      },
      {
        key: "WorkflowSaasQueryEditor",
        component: WorkflowQueryEditor,
        path: `${WORKFLOW_EDITOR_URL}${SAAS_EDITOR_API_ID_PATH}`,
        exact: true,
      },
      {
        key: "WorkflowApiEditor",
        component: WorkflowApiEditor,
        path: `${WORKFLOW_EDITOR_URL}${WORKFLOW_API_EDITOR_PATH}`,
        exact: true,
      },
      {
        key: "WorkflowJsEditor",
        component: WorkflowJsEditor,
        path: `${WORKFLOW_EDITOR_URL}${WORKFLOWS_JS_COLLECTION_EDITOR_PATH}`,
        exact: true,
      },
      {
        key: "WorkflowTriggerSettings",
        component: TriggerWorkflowSettings,
        path: WORKFLOW_TRIGGER_SETTINGS_PATH(path),
        exact: true,
      },
      {
        key: "WorkflowGeneralSettings",
        component: GeneralWorkflowSettings,
        path: WORKFLOW_GENERAL_SETTINGS_PATH(path),
        exact: true,
      },
    ];
  }

  return [...workflowRoutes, ...ceRoutes, ...moduleRoutes];
}

export default useRoutes;
