import React from "react";
import { useSelector } from "react-redux";
import { selectLibrariesForExplorer } from "ee/selectors/entitiesSelector";
import { animated, useTransition } from "react-spring";
import { LibraryEntity } from "pages/Editor/Explorer/Libraries";
import styled from "styled-components";
import PaneHeader from "pages/Editor/IDE/LeftPane/PaneHeader";
import { Divider, Flex } from "@appsmith/ads";

const Container = styled.div`
  width: 250px;
`;

const Libraries = () => {
  const libraries = useSelector(selectLibrariesForExplorer);
  const transitions = useTransition(libraries, {
    keys: (lib) => lib.name,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  });
  return (
    <Flex>
      <Container>
        <PaneHeader title="Installed Libraries" />
        {transitions((style, lib) => (
          <animated.div style={style}>
            <LibraryEntity lib={lib} />
          </animated.div>
        ))}
      </Container>
      <Divider orientation="vertical" />
    </Flex>
  );
};

export default Libraries;
