export * from "ce/pages/Editor/IDE/MainPane/useMissingModuleNotification";

import React from "react";
import { getAllModuleInstances } from "ee/selectors/moduleInstanceSelectors";
import {
  MISSING_PACKAGE_OR_MODULE,
  OPEN_DEBUGGER_CTA,
  createMessage,
} from "ee/constants/messages";
import { Callout } from "@appsmith/ads";
import { useDispatch, useSelector } from "react-redux";
import { setDebuggerSelectedTab, showDebugger } from "actions/debuggerActions";
import { DEBUGGER_TAB_KEYS } from "components/editorComponents/Debugger/helpers";

function useMissingModuleNotification(): React.ReactNode | null {
  const dispatch = useDispatch();
  const moduleInstances = useSelector(getAllModuleInstances);
  const hasMissingModule = Object.values(moduleInstances).some(
    (mi) => (mi.invalids || []).length > 0,
  );

  if (!hasMissingModule) return null;

  const openDebugger = () => {
    dispatch(showDebugger(true));
    dispatch(setDebuggerSelectedTab(DEBUGGER_TAB_KEYS.ERROR_TAB));
  };

  return (
    <Callout
      isClosable
      kind="error"
      links={[
        { children: createMessage(OPEN_DEBUGGER_CTA), onClick: openDebugger },
      ]}
    >
      {createMessage(MISSING_PACKAGE_OR_MODULE)}
    </Callout>
  );
}

export default useMissingModuleNotification;
