import {
  EditorState,
  SidebarBottomButtonTitles,
} from "ee/entities/IDE/constants";
import { DEFAULT_TAB } from "./LeftPane/SettingsPane";
import type { IDESidebarButton } from "IDE";

export const BottomButtons: IDESidebarButton[] = [
  {
    state: EditorState.LIBRARIES,
    icon: "packages-v3",
    tooltip: SidebarBottomButtonTitles.LIBRARIES,
    urlSuffix: "libs",
  },
  {
    state: EditorState.SETTINGS,
    icon: "settings-v3",
    tooltip: SidebarBottomButtonTitles.SETTINGS,
    urlSuffix: `settings/${DEFAULT_TAB}`,
  },
];
