import React from "react";
import { Container, Wrapper } from "components/BottomBar/components";
import { DebuggerTrigger } from "components/editorComponents/Debugger";
import { RunHistoryTrigger } from "./WorkflowRunHistory/RunHistoryTrigger";
import { useCurrentAppState } from "pages/Editor/IDE/hooks";
import { EditorState } from "ee/entities/IDE/constants";

export default function BottomBar() {
  const editorState = useCurrentAppState();
  return (
    <Container>
      <Wrapper>
        <div data-testid="t--switch-env" />
      </Wrapper>
      <Wrapper>
        <DebuggerTrigger />
        {editorState === EditorState.EDITOR && <RunHistoryTrigger />}
        <div data-testid="t--help-button" />
      </Wrapper>
    </Container>
  );
}
